






















.drag {
  display: inline-flex;
  cursor: grab;
}

.drag-column {
  position: relative;
  width: 4px;
  height: 18px;
}

.drag-point {
  position: absolute;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  margin: 3px 1px;
  background-color: #646464;

  &:nth-child(2) {
    top: 4px;
  }

  &:nth-child(3) {
    top: 8px;
  }
}
