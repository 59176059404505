






























































































































































































































































































































































































































































































































































.changed-node {
  background-color: #efefef !important;

  .router-link {
    color: #808080 !important;
  }
}
